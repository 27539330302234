<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent"> </TablePage>
  </div>
</template>
<script>
// 商城订单支付查询
import TablePage from "@/components/tablePage"; //表格页面组件
import {
  paymentDetailsList,
  paymentDetailsListSummary,
  shopPaymentShopCashierList,
  shopPaymentShopCashierListSummary,
  shopPaymentList,
  shopPaymentListSummary,
  paymentList,
  paymentListSummary,
  shopPaymentCashierList,
  shopPaymentCashierListSummary,
  shopPaymentDateList,
  shopPaymentDateListSummary,
  paymentGoodsDetailsList,
  paymentGoodsDetailsListSummary,
} from "@/api/O2OMall/orderManage/order";
export default {
  name: "PosGather",
  components: {
    TablePage,
  },
  data() {
    return {
      options: {
        loading: false,
        pagination: {
          total: 0,
          page: 1,
          size: 15,
        },
        title: "门店收款分析",
        listNo: true, // 序号
        //搜索
        search: [
          {
            label: "订单时间",
            type: "datetimerange",
            model: "",
            noReset: true,
            filterFnc: ([a, b] = []) => ({ beginBillDate: a, endBillDate: b }),
          },
          {
            keyIndex: 0,
            format: "datetimerange",
            type: "timeRange",
            defaultValue: "day",
            data: ["day", "yesterday", "week", "lastWeek", "month", "lastMonth"],
          },
          {
            type: "filters",
            tip: "门店编号/门店名称/小票号",
            model: "",
            filters: [
              { filter: "query", label: "全部" },
              { filter: "shopNos", label: "门店编号" },
              { filter: "shopNames", label: "门店名称" },
              { filter: "billNos", label: "小票号" },
            ],
          },
          this.$select({
            key: "listShop",
            option: {
              filter: "shopIds",
              seniorSearch: true,
              hide: () =>
                this.options?.tabColumnType == "payDetail" ||
                this.options?.tabColumnType == "payShopPayTpesCashier" ||
                this.options?.tabColumnType == "payShop" ||
                this.options?.tabColumnType == "payDetailGood",
              option: {
                multiple: true,
                buttons: [
                  {
                    type: "more",
                    option: {
                      title: "选择门店",
                      width: 1250,
                      type: "TreeAndTable",
                      formData: this.$dialog({ key: "shop" }),
                    },
                  },
                ],
              },
            },
          }),
          this.$select({
            key: "listPayClass",
            option: {
              filter: "payModeIds",
              label: "收款方式",
              seniorSearch: true,
              hide: () =>
                this.options?.tabColumnType == "payDetail" ||
                this.options?.tabColumnType == "payShopPayTpesCashier" ||
                this.options?.tabColumnType == "payPayTpes" ||
                this.options?.tabColumnType == "payPayTpesCashier" ||
                this.options?.tabColumnType == "payDetailGood",
              option: {
                multiple: true,
                buttons: [
                  {
                    type: "more",
                    option: {
                      title: "选择收款方式",
                      width: 1250,
                      type: "TreeAndTable",
                      formData: this.$dialog({ key: "paymode" }),
                    },
                  },
                ],
              },
            },
          }),
          this.$select({
            key: "listCashier",
            option: {
              filter: "cashierIds",
              label: "收银员",
              seniorSearch: true,
              hide: () =>
                this.options?.tabColumnType == "payDetail" ||
                this.options?.tabColumnType == "payShopPayTpesCashier" ||
                this.options?.tabColumnType == "payPayTpesCashier",
              option: {
                multiple: true,
                buttons: [
                  {
                    type: "more",
                    option: {
                      title: "选择收银员",
                      width: 1250,
                      type: "TreeAndTable",
                      formData: this.$dialog({ key: "cashier" }),
                    },
                  },
                ],
              },
            },
          }),
          this.$select({
            key: "listGoods",
            option: {
              filter: "goodsIds",
              label: "商品",
              seniorSearch: true,
              hide: () => this.options?.tabColumnType == "payDetailGood",
              option: {
                multiple: true,
                buttons: [
                  {
                    type: "more",
                    option: {
                      title: "选择商品",
                      width: 1250,
                      type: "TreeAndTable",
                      formData: this.$dialog({ key: "goods" }),
                    },
                  },
                ],
              },
            },
          }),
          {
            label: "是否营收",
            seniorSearch: true,
            type: "local",
            model: "",
            filter: "isRevenue",
            option: {
              clearable: true,
              data: [
                { label: "是", value: true },
                { label: "否", value: false },
              ],
              label: "label",
              value: "value",
              filterable: true,
            },
          },
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          {
            type: "button",
            tip: "高级查询",
            btnType: "primary",
            click: "seniorSearch",
          },
          { type: "button", tip: "重置", click: "reset" },
        ],
        tabColumnType: "payDetail",
        tabsColumns: [
          {
            title: "收款汇总明细分析",
            type: "payDetail",
            getListApi: paymentDetailsList,
            getSummaryApi: paymentDetailsListSummary,
            uuid: "596de9c4-01bd-0fd1-4ec2-45787054d8a9",
            // defaultBody: { rankingType: 1 },
            exportOption: {
              fastExportUrl:
                "/api/system/mall/report/pay/analysis/paymentDetailsListExport",
              exportName: "收款汇总明细分析",
            },
            //表格
            columns: [
              {
                prop: "billDate",
                label: "订单时间",
                minWidth: 155,
                align: "center",
              },
              {
                prop: "billNo",
                label: "订单编号",
                minWidth: 180,
                align: "center",
                type: "link",
                click: "update",
              },
              {
                prop: "shopNo",
                label: "门店编码",
                minWidth: 150,
                align: "center",
              },
              {
                prop: "shopName",
                label: "门店名称",
                minWidth: 150,
                align: "center",
              },
              {
                prop: "billFactMoney",
                label: "收款金额",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "equalScoreTotal",
                label: "兑换积分",
                minWidth: 120,
              },
              {
                prop: "payTypse",
                align: "center",
                label: "收款方式",
                children: () =>
                  this.options.list?.[0]?.payTypes?.map?.((x) => ({
                    ...x,
                    label: x.payModeName,
                    prop: `payDetailList.${x.payModeId}.payModeId.payMoney`,
                    minWidth: 110,
                    summary: true,
                    getValue: (row, prop) =>
                      row.payDetailList?.find((y) => y.payModeId === x.payModeId)
                        ?.payMoney || 0,
                  })) || [],
              },
            ],
            summary: ["billFactMoney", 'equalScoreTotal'],
          },
          {
            title: "收款汇总查询(按门店+收款方式)",
            type: "payShopPayTpesCashier",
            getListApi: shopPaymentShopCashierList,
            getSummaryApi: shopPaymentShopCashierListSummary,
            uuid: "00708ac3-52d4-6b60-3b1f-bf30f3c84e1a",
            // defaultBody: { rankingType: 1 },
            exportOption: {
              fastExportUrl:
                "/api/system/mall/report/pay/analysis/shopPaymentShopCashierListExport",
              exportName: "收款汇总查询(按门店+收款方式)",
            },
            //表格
            columns: [
              {
                prop: "billDateStr",
                label: "订单日期",
                minWidth: 155,
                align: "center",
              },
              {
                prop: "shopNo",
                label: "门店编号",
                minWidth: 150,
                align: "center",
              },
              {
                prop: "shopName",
                label: "门店名称",
                minWidth: 150,
                align: "center",
              },
              {
                prop: "payModeName",
                label: "收款方式",
                minWidth: 120,
              },
              {
                prop: "payCount",
                label: "收款笔数",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "payMoney",
                label: "收款金额",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "equalScoreTotal",
                label: "兑换积分",
                minWidth: 120,
              },
            ],
            summary: ["payCount", "payMoney", 'equalScoreTotal'],
          },
          {
            title: "收款汇总查询(按门店)",
            type: "payShop",
            getListApi: shopPaymentList,
            getSummaryApi: shopPaymentListSummary,
            uuid: "7c1cd2a6-8fe5-f570-ad88-263cb76f2284",
            // defaultBody: { rankingType: 1 },
            exportOption: {
              fastExportUrl: "/api/system/mall/report/pay/analysis/shopPaymentListExport",
              exportName: "收款汇总查询(按门店)",
            },
            //表格
            columns: [
              {
                prop: "billDateStr",
                label: "订单日期",
                minWidth: 155,
                align: "center",
              },
              {
                prop: "shopNo",
                label: "门店编号",
                minWidth: 150,
                align: "center",
              },
              {
                prop: "shopName",
                label: "门店名称",
                minWidth: 150,
                align: "center",
              },
              {
                prop: "payCount",
                label: "收款笔数",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "payMoney",
                label: "收款金额",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "equalScoreTotal",
                label: "兑换积分",
                minWidth: 120,
              },
            ],
            summary: ["payCount", "payMoney", 'equalScoreTotal'],
          },
          {
            title: "收款汇总查询(按收款方式)",
            type: "payPayTpes",
            getListApi: paymentList,
            getSummaryApi: paymentListSummary,
            uuid: "1b829932-a71c-9d4b-ec8c-4dbe1890a441",
            // defaultBody: { rankingType: 1 },
            exportOption: {
              fastExportUrl: "/api/system/mall/report/pay/analysis/paymentListExport",
              exportName: "收款汇总查询(按收款方式)",
            },
            //表格
            columns: [
              {
                prop: "payModeName",
                label: "收款方式",
                minWidth: 120,
              },
              {
                prop: "payCount",
                label: "收款笔数",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "payMoney",
                label: "收款金额",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "equalScoreTotal",
                label: "兑换积分",
                minWidth: 120,
              },
            ],
            summary: ["payCount", "payMoney", 'equalScoreTotal'],
          },
          {
            title: "收款汇总查询(按日期)",
            type: "payDate",
            getListApi: shopPaymentDateList,
            getSummaryApi: shopPaymentDateListSummary,
            uuid: "6d9efe8f-5030-96bb-1549-0a3d14881199",
            // defaultBody: { rankingType: 1 },
            exportOption: {
              fastExportUrl:
                "/api/system/mall/report/pay/analysis/shopPaymentDateListExport",
              exportName: "收款汇总查询(按日期)",
            },
            //表格
            columns: [
              {
                prop: "billDateStr",
                label: "订单日期",
                minWidth: 155,
                align: "center",
              },
              {
                prop: "payCount",
                label: "收款笔数",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "payMoney",
                label: "收款金额",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "equalScoreTotal",
                label: "兑换积分",
                minWidth: 120,
              },
            ],
            summary: ["payCount", "payMoney", 'equalScoreTotal'],
          },
        ],
      },
    };
  },
  methods: {
    async handleEvent(type, row) {
      switch (type) {
        case "update":
          if (row?.payTypeId != 1 && row?.payTypeId != 4) {
            this.$router.push({
              name: "saleTotalDetail",
              query: {
                billNo: row.billNo,
                type: "Update",
              },
            });
          } else {
            this.$router.push({
              name: "saleTotalDetail",
              query: {
                billId: row.billId,
                type: "Update",
              },
            });
          }

          break;
        default:
          break;
      }
    },
  },
};
</script>
